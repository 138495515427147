<template>
  <div>

    <b-row>
      <b-col md="12">
        <validation-provider
          #default="{ errors }"
          rules="required"
        >
          <b-form-group
            :label="$tc('Contacto', 1)"
            :class="errors.length > 0 ? 'is-invalid':null"
          >
            <v-select
              v-model="familiar"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="search"
              append-to-body
              :calculate-position="withPopper"
              :filterable="false"
              label="desc"
              item-text="desc"
              item-value="id"
              @search="onSearchByUserAuth"
              @input="getAllGrausParentesco"
            >
              <div
                v-if="search.length > 0"
                slot="no-options"
              >
                {{ $t('Sem resultados') }}
              </div>
              <template
                v-if="search.length === 0"
                slot="no-options"
              >
                {{ $t('Digite o nome') }}
              </template>
              <template
                slot="option"
                slot-scope="option"
              >
                <div class="d-flex align-items-center">
                  <b-avatar
                    :src="option.photo"
                    class="mr-50"
                  />
                  <div class="detail">
                    <b-card-text class="mb-0">
                      {{ option.desc }}
                    </b-card-text>
                    <b-card-text
                      v-if="option.email"
                      class="mb-0 text-muted"
                    >
                      <small>{{ option.email }}</small>
                    </b-card-text>
                    <b-card-text
                      v-if="option.phone"
                      class="mb-0 text-muted"
                    >
                      <small>{{ option.phone }}</small>
                    </b-card-text>
                  </div>
                </div>
              </template>
              <template
                slot="selected-option"
                slot-scope="option"
              >
                {{ option.desc }}
              </template>
            </v-select>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <validation-provider
          #default="{ errors }"
          rules="required"
        >
          <b-form-group
            :label="$t('Grau parentesco')"
            :class="errors.length > 0 ? 'is-invalid':null"
          >
            <v-select
              ref="grauParentesco"
              v-model="familiar_grau"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="grausParentesco"
              append-to-body
              :calculate-position="withPopper"
              :loading="loaderGrau"
              label="sw024s02"
              item-text="sw024s02"
              item-value="sw024s01"
              :placeholder="((familiar === null) ? $t('Selecione primeiro um contacto') : $t('Escolha um grau') )"
            >
              <template #option="{ sw024s02 }">
                {{ sw024s02 }}
              </template>
              <div
                v-if="familiar === null"
                slot="no-options"
              >
                {{ $t('Selecione primeiro um contacto') }}
              </div>
              <div
                v-if="familiar !== null"
                slot="no-options"
              >
                {{ $t('Sem resultados') }}
              </div>
            </v-select>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import {
  BFormGroup, BRow, BCol, BAvatar, BCardText,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import modelSW025 from '@store-modules/crm/leads/data'
import { debounce } from '@core-custom/mixins/geral'
import { mixFormGeral } from '../../../mixins'

export default {
  components: {
    BFormGroup,
    BRow,
    BCol,
    vSelect,
    ValidationProvider,
    BAvatar,
    BCardText,
  },
  mixins: [mixFormGeral],
  props: {
  },
  data() {
    return {
      familiar: null,
      familiar_grau: null,
      search: [],
      grausParentesco: [],
      loaderGrau: false,
    }
  },
  methods: {
    resetGrau() {
      this.familiar = null
      this.familiar_grau = null
      this.grausParentesco = []
    },
    onSearchByUserAuth(search, loading) {
      if (search.length) {
        loading(true)
        this.searchByUserAuth(loading, search, this)
      }
    },
    searchByUserAuth: debounce((loading, search, vm) => {
      const oThis = vm

      vm.$store.dispatch('sw025/searchContactosByUserAuth', search).then(res => {
        oThis.search = res
        loading(false)
      }).catch(error => {
        vm.showMsgErrorRequest(error)
        oThis.search = []
        loading(false)
      })
    }, 500),
    async getAllGrausParentesco() {
      try {
        this.familiar_grau = null
        this.grausParentesco = []

        if ((this.familiar !== null) && (this.familiar !== undefined) && ('genero' in this.familiar)) {
          this.loaderGrau = true
          await this.$store.dispatch('sw025/getAllGrausParentesco', this.familiar.genero).then(res => {
            this.loaderGrau = false
            this.grausParentesco = res
          }).catch(error => {
            this.loaderGrau = false
            this.showMsgErrorRequest(error)
          })
        }
      } catch (err) {
        //
      }
    },
    clearForm() {
      this.resetGrau()
      this.search = []
    },
    getDataSaveForm() {
      return new Promise(resolve => {
        resolve({
          lead: this.familiar.id,
          grau: this.familiar_grau.sw024s01,
        })
      })
    },
  },
  setup() {
    const NAMESPACE_SW025_FORM_DATA = 'sw025'

    if (!store.hasModule(NAMESPACE_SW025_FORM_DATA)) {
      store.registerModule(NAMESPACE_SW025_FORM_DATA, modelSW025)

      onUnmounted(() => {
        if (store.hasModule(NAMESPACE_SW025_FORM_DATA)) store.unregisterModule(NAMESPACE_SW025_FORM_DATA)
      })
    }

    return {
      NAMESPACE_SW025_FORM_DATA,
    }
  },
}
</script>
